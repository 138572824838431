import { Button, Card, Col, Form, Input, Typography, Row, Space, message, Select } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useDebounce, useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BatchesTable } from '../components/batches-table';
import { translationNamespace } from '../constants/translation-resources';
import { useTableOptions } from '../../shared/hooks/use-data-options';
import { Batch, BeDocumentFilters, DocumentsFilters } from '../models/batch';
import { Orders } from '../../shared/constants/order';
import { Order } from '../../shared/models/order';
import { Feature } from '../../shared/models/features';
import { userSelector } from '../../shared/data/store/selectors/auth-selectors';
import { useFindBatchesQuery, useGetFiltersQuery, useGetReasonsQuery } from '../data/queries/batch-queries';
import { BatchesTableFiltersForm } from '../components/batches-table/filters';
import { DocumentsListView } from './documents-list';
import { convertDocumentsFilters } from '../data/converters/documents-filters-converters';
import { useHandleBatchActionMutation } from '../data/mutations/batch-mutations';
import { DocumentActions, DocumentAction } from '../constants/documents';
import { ConfirmationModal } from '../../shared/components/confirmation-modal';
import { isUserAuthorized } from '../../shared/utils/allowed-routes';
import {
  approveBatchLayoutRequiredRoute,
  approveBothBatchRequiredRoute,
  approveQuantityBatchRequiredRoute,
  rejectBatchRequiredRoute,
  setFixDateRequiredRoute,
} from '../constants/required-routes';
import { useGetUserAllowedRoutesQuery } from '../../core/data/queries/core-queries';
import { useApprovalService } from '../data/services/use-approval-service';
import { displayErrors } from '../../shared/utils/error';
import { languageReasonsRequestMap } from '../constants/translation-resources';
import { SetFixDateModal } from '../components/set-fix-date-modal';
import { approvedBatchAndDocumentStatus, disabledBatchAndDocumentStatus } from '../models/document';

const { Search, TextArea } = Input;
const { Text } = Typography;

const defaultOrder: [keyof Batch, Order][] = [['expireTime', Orders.ASC]];

export const BatchesListView: FC = () => {
  const { t, i18n } = useTranslation(translationNamespace);
  const currentLanguage = i18n.language;
  const { page, pageSize, setPage, order, handleTableChange } = useTableOptions<Batch>(defaultOrder);
  const currentUser = useSelector(userSelector);
  const actionMutation = useHandleBatchActionMutation();
  const [searchValue, setSearchValue] = useState<string>();
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filter, setFilter] = useState<BeDocumentFilters>(null);
  const [filtersForm] = Form.useForm<DocumentsFilters>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [selectedBatchIds, setSelectedBatchIds] = useState<React.Key[]>([]);
  const [rejectionReason, setRejectionReason] = useState<string>();
  const [actionType, setActionType] = useState<DocumentAction>(null);
  const [textareaText, setTextareaText] = useState<string>();
  const [isDownloadingBatches, setIsDownloadingBatches] = useState<boolean>(false);
  const { data: batchesData, isLoading: isBatchesDataLoading } = useFindBatchesQuery({
    page,
    pageSize,
    sortBy: order[0]?.[0],
    sortDirection: order[0]?.[1],
    search: debouncedSearchValue,
    groupId: currentUser?.groups[0]?.id,
    filter,
  });
  const {
    data: filtersColumns,
    isLoading: areFiltersLoading,
    error: filtersError,
  } = useGetFiltersQuery(currentUser?.groups[0]?.id, showFilters);
  const { data: userAllowedRoutes } = useGetUserAllowedRoutesQuery();
  const { downloadBatch } = useApprovalService();
  const isRejectedType = actionType === DocumentActions.REJECTED;
  const { data: reasons, isLoading: areReasonsLoading } = useGetReasonsQuery(
    {
      groupId: currentUser?.groups[0]?.id,
      type: 'batch',
      typeAction: DocumentActions.REJECTED,
      lang: languageReasonsRequestMap[currentLanguage],
    },
    isRejectedType
  );
  const [isFixDateModalOpen, toggleFixDateModalOpen] = useToggle(false);

  const contentText = isRejectedType ? 'reject.modalContent' : 'approve.modalContent';
  const isAuthorizedToApproveLayout = isUserAuthorized(approveBatchLayoutRequiredRoute, userAllowedRoutes);
  const isAuthorizedToApproveNumber = isUserAuthorized(approveQuantityBatchRequiredRoute, userAllowedRoutes);
  const isAuthorizedToApproveBoth = isUserAuthorized(approveBothBatchRequiredRoute, userAllowedRoutes);
  const isAuthorizedToReject = isUserAuthorized(rejectBatchRequiredRoute, userAllowedRoutes);
  const isAuthorizedToSetFixDate = isUserAuthorized(setFixDateRequiredRoute, userAllowedRoutes);

  const applyFilters = tableFilters => {
    const filters = convertDocumentsFilters(tableFilters, filtersColumns.filter.columns);

    setFilter(filters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  const onFinishAction = () => {
    setSelectedBatchIds([]);
    setActionType(null);
    setRejectionReason(undefined);
    setTextareaText('');
  };

  const onCancel = () => {
    setActionType(null);
    setRejectionReason(undefined);
    setTextareaText('');
  };

  const confirmAction = () => {
    const success = isRejectedType ? 'reject.success' : 'approve.success';

    actionMutation.mutate(
      {
        user: currentUser.username,
        msg: rejectionReason,
        comment: textareaText,
        wIds: selectedBatchIds as string[],
        actionType: actionType,
      },
      {
        onSuccess: () => {
          message.success(
            t(success, {
              resourceType: t('batches.batch'),
            })
          );
        },
        onSettled: () => onFinishAction(),
      }
    );
  };

  const sharedButtonProps = {
    type: 'primary' as ButtonType,
    size: 'large' as SizeType,
    disabled: !(selectedBatchIds.length > 0),
    style: { marginTop: '10px', marginLeft: '20px' },
  };

  const download = async () => {
    setIsDownloadingBatches(true);

    try {
      const resourcesToDownload = batchesData?.resources?.filter(resource =>
        selectedBatchIds.some(wId => wId === resource.wId)
      );

      if (resourcesToDownload.length === 0) return;

      for (const { fileName, pdfLink } of resourcesToDownload) {
        try {
          const { data } = await downloadBatch(pdfLink);
          const blob = new Blob([data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        } catch (error) {
          displayErrors(error, t);
        }
      }
    } catch (error) {
      displayErrors(error, t);
    } finally {
      setIsDownloadingBatches(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedBatchIds,
    onChange: setSelectedBatchIds,
    getCheckboxProps: (batch: Batch) => ({
      disabled:
        disabledBatchAndDocumentStatus.includes(batch.status) || approvedBatchAndDocumentStatus.includes(batch.status),
    }),
  };

  const applyStyles = (batch: Batch) => {
    return disabledBatchAndDocumentStatus.includes(batch.status)
      ? { style: { opacity: 0.5, backgroundColor: '#7F7F7F' } }
      : approvedBatchAndDocumentStatus.includes(batch.status)
      ? { style: { opacity: 0.5, backgroundColor: '#6CA885' } }
      : {};
  };

  const renderRowClassName = row => {
    if (row.status === 'approved' || row.status === 'rejected') {
      return 'disabled-row';
    }
    return '';
  };

  const expandedRowRender = (batch: Batch) => {
    return (
      <DocumentsListView batchName={batch.fileName} filter={filter} search={debouncedSearchValue} wId={batch.wId} />
    );
  };

  const fixDateBatches = useMemo(() => {
    if (batchesData == null) return [];

    return batchesData?.resources.reduce((acc, batch) => {
      if (selectedBatchIds.includes(batch.wId)) {
        acc.push(batch);
      }
      return acc;
    }, [] as Batch[]);
  }, [batchesData, selectedBatchIds]);

  useDebounce(
    () => {
      if (searchValue != null) {
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  useEffect(() => {
    if (filtersError != null) {
      toggleShowFilters(false);
    }
  }, [filtersError, toggleShowFilters]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <Col style={{ width: '100%' }}>
        <Space>
          <StyledSearch
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={t(`${Feature.SHARED}:search`)}
            onSearch={applySearch}
            allowClear
            size="large"
          />
          <Button type="link" onClick={toggleShowFilters}>
            {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
          </Button>
        </Space>
      </Col>
      {showFilters && filtersError == null && (
        <Col span={24}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <BatchesTableFiltersForm
              form={filtersForm}
              onApply={applyFilters}
              filtersColumns={filtersColumns}
              areFiltersLoading={areFiltersLoading}
            />
          </Card>
        </Col>
      )}
      <Col span={24}>
        <Row style={{ justifyContent: 'end' }}>
          {isAuthorizedToReject && (
            <Button {...sharedButtonProps} onClick={() => setActionType(DocumentActions.REJECTED)}>
              {t('reject.button')}
            </Button>
          )}
          {(isAuthorizedToApproveLayout || isAuthorizedToApproveBoth) && (
            <Button
              css={marginButton}
              onClick={() => setActionType(DocumentActions.APPROVED_LAYOUT)}
              {...sharedButtonProps}
            >
              {t('approve.layout')}
            </Button>
          )}
          {(isAuthorizedToApproveNumber || isAuthorizedToApproveBoth) && (
            <Button
              css={marginButton}
              onClick={() => setActionType(DocumentActions.APPROVED_QUANTITY)}
              {...sharedButtonProps}
            >
              {t('approve.quantity')}
            </Button>
          )}
          {isAuthorizedToApproveBoth && (
            <Button
              css={marginButton}
              onClick={() => setActionType(DocumentActions.APPROVED_BOTH)}
              {...sharedButtonProps}
            >
              {t('approve.both')}
            </Button>
          )}
          <Button
            {...sharedButtonProps}
            disabled={!batchesData?.resources || batchesData?.resources.length === 0}
            onClick={download}
            loading={isDownloadingBatches}
          >
            {t(`${Feature.SHARED}:download`)}
          </Button>
          {isAuthorizedToSetFixDate && (
            <Button {...sharedButtonProps} disabled={selectedBatchIds.length === 0} onClick={toggleFixDateModalOpen}>
              {t('batches.fixDate.set')}
            </Button>
          )}
        </Row>
        <StyledTable
          dataSource={batchesData?.resources}
          loading={isBatchesDataLoading}
          rowSelection={rowSelection}
          scroll={{ x: 600 }}
          pagination={{
            total: batchesData?.total,
            current: page,
            pageSize,
            showSizeChanger: batchesData?.total > 10,
          }}
          onRow={batch => applyStyles(batch)}
          rowClassName={renderRowClassName}
          onChange={handleTableChange}
          defaultOrder={defaultOrder[0]}
          title={() => (
            <Text strong>
              {t(`${Feature.APPROVAL}:documentsTable.totalDocuments`)} {batchesData?.numberOfDocuments}
            </Text>
          )}
          expandable={{ expandedRowRender }}
        />
      </Col>

      <SetFixDateModal
        toggleModal={toggleFixDateModalOpen}
        batches={fixDateBatches}
        open={isFixDateModalOpen}
        onCancel={toggleFixDateModalOpen}
      />

      <ConfirmationModal
        destroyOnClose
        open={actionType != null}
        title={t(`${Feature.SHARED}:areYouSure`)}
        content={t(contentText, {
          buttonTitle: t(`${Feature.SHARED}:confirm`),
          amount: `${selectedBatchIds.length}`,
          resourceType: t('batches.batch'),
        })}
        onCancel={onCancel}
        onOk={confirmAction}
        okButtonProps={{ disabled: isRejectedType && rejectionReason == null }}
        children={
          isRejectedType && (
            <>
              <p style={{ marginTop: '10px', width: '100%' }}>{t('reject.reason')}</p>
              <Select
                style={{ width: '100%' }}
                options={reasons}
                onSelect={setRejectionReason}
                loading={areReasonsLoading}
              />
              <TextArea
                style={{ marginTop: '10px' }}
                rows={4}
                placeholder={t('reject.comment')}
                onChange={event => setTextareaText(event.target.value)}
              />
            </>
          )
        }
      />
    </Row>
  );
};

const StyledSearch = styled(Search)`
  max-width: 320px;
`;

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

export const marginButton = css`
  margin-left: 20px;
`;

const StyledTable = styled(BatchesTable)`
  @media (min-width: 2051px) {
    max-width: calc(100vw - 250px);
  }
`;
